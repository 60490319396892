<template>
    <div >
        <div class="d-flex align-items-center btn-alignment">
            <vs-select v-if="!this.isEditMode"  :disabled="editBankAccount"
            class="selectBankAccount"
            v-bind:class="{ required: IsRequired }"
            :label="$t('USERADMIN.BANKACCOUNT.USE_THIS')"
            v-model="selectBankAccount">
            <template v-for="(item,index) in bankAccountBez">
              <vs-select-item v-if="item.value != -1" :key="index" :value="item.value" :text="item.text"  />
            </template>
            <vs-button class="mt-2 fullsize" size="small" color="primary" type="border" icon="add" @click="AddNewBankAccount()">{{$t('USERADMIN.BANKACCOUNT.NEW_BANKACCOUNT')}}</vs-button>
          </vs-select>
          <vs-input v-if="isEditMode" :label="$t('USERADMIN.BANKACCOUNT.BEZEICHNUNG')" class="inputx" v-bind:class="{ required: IsRequired }" :placeholder="$t('USERADMIN.BANKACCOUNT.BEZEICHNUNG')"  v-model="name" />
        </div>
        <vs-divider position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.BANKACCOUNT.BANKNAME')"  class="inputx" :placeholder="$t('USERADMIN.BANKACCOUNT.BANKNAME')"  v-model="bank_name" />
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.BANKACCOUNT.OWNER_NAME')"  class="ml-3 inputx" :placeholder="$t('USERADMIN.BANKACCOUNT.OWNER_NAME')"  v-model="name_owner" />
        </div> 
        <div class="mt-2 default-input d-flex align-items-center">
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.BANKACCOUNT.IBAN')"  class="inputx" :placeholder="$t('USERADMIN.BANKACCOUNT.IBAN')"  v-model="iban" />
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.BANKACCOUNT.BIC')"  class="ml-3 inputx" :placeholder="$t('USERADMIN.BANKACCOUNT.BIC')"  v-model="bic" />
        </div>         
        <vs-divider v-if="!NewUser && editBankAccount" position="center" ></vs-divider>
        <div class="mt-2 default-input d-flex align-items-center">
          <vs-button v-if="!NewUser && editBankAccount"  type="border" icon="done" @click="Save" >{{$t('COM.SAVE')}}</vs-button>
          <vs-button class="ml-2" v-if="!NewUser && editBankAccount && selectBankAccount <= 0"  type="border" icon="clear" @click="SelectPrev" >{{$t('COM.CANCEL')}}</vs-button>
        </div>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';

export default {
  name: "BankAccount",
  components:{
      
  },
  props:[
    'BankAccountId',
    'OwnerId',
    'EditMode',
    'IsRequired',
    'NewUser',
    'OwnerType'
  ],
   data: function (){
     return {
    selectBankAccount: this.BankAccountId,
    editBankAccount: this.EditMode,
    selectBankAccountOld: this.BankAccountId
     }
  },
  mounted () {

      if(this.$store.state.bankaccount.bankaccountListUser.data == null)
        this.$store.dispatch('bankaccount/getBankAccountListOfGuid', {id:this.OwnerId,ownertype:this.OwnerType,forTable:false}); 
        
      this.UpdateAccountType();
  },
  watch:{
    selectBankAccount: function (value,oldValue){
        this.$emit('SelectedBankAccountChanged',value);

        if(value <= 0)
        {
         this.StartEditMode();
        }

        this.selectBankAccountOld = oldValue;
    },
    EditMode: function (value)
    {
      this.editBankAccount = value;
    }
  },
  computed:{
    bankAccountBez: function (){
      var ret = [];
      if(this.$store.state.bankaccount.bankaccountListUser.data != null)
      {
        for (let i = 0; i < this.$store.state.bankaccount.bankaccountListUser.data.length; i++)
        {
            var val = {"text": this.$store.state.bankaccount.bankaccountListUser.data[i].name, "value":this.$store.state.bankaccount.bankaccountListUser.data[i].id};
            ret.push(val);
        }

        if(this.$store.state.bankaccount.bankaccountListUser.data.length == 1)
        {
          this.StartEditMode();
        }
      }

      return ret;
    },
    isEditMode(){
        return this.editBankAccount || this.NewUser;
    },
    name: 
    {
      get () {
        return this.GetBankAccountVal(this.selectBankAccount,'name');
      },
      set (value) {
        var data = {"value": value, "id":this.selectBankAccount};
        this.$store.dispatch('bankaccount/updateBankAccountListName', data);
        this.UpdateAccountType();
      }
    },
    bank_name: 
    {
      get () {
        return this.GetBankAccountVal(this.selectBankAccount,'bank_name');
      },
      set (value) {
        var data = {"value": value, "id":this.selectBankAccount};
        this.$store.dispatch('bankaccount/updateBankAccountListBankName', data);
        this.UpdateAccountType();
      }
    },
    iban: 
    {
      get () {
        return this.GetBankAccountVal(this.selectBankAccount,'iban');
      },
      set (value) {
        var data = {"value": value, "id":this.selectBankAccount};
        this.$store.dispatch('bankaccount/updateBankAccountListIban', data);
        this.UpdateAccountType();
      }
    },
    bic: 
    {
      get () {
        return this.GetBankAccountVal(this.selectBankAccount,'bic');
      },
      set (value) {
        var data = {"value": value, "id":this.selectBankAccount};
        this.$store.dispatch('bankaccount/updateBankAccountListBic', data);
        this.UpdateAccountType();
      }
    },
    name_owner:
    {
      get () {
        return this.GetBankAccountVal(this.selectBankAccount,'name_owner');
      },
      set (value) {
        var data = {"value": value, "id":this.selectBankAccount};
        this.$store.dispatch('bankaccount/updateBankAccountListNameOwner', data);
        this.UpdateAccountType();
      }
    }
  },
  methods:{
    ...helperMethods,
    AddNewBankAccount(){
      this.selectBankAccount = -1;
    },
    GetBankAccountVal: function (id,prop){
      var ret = "";
      if(this.$store.state.bankaccount.bankaccountListUser.data != null)
      {
        for (let i = 0; i < this.$store.state.bankaccount.bankaccountListUser.data.length; i++)
        {
            if(this.$store.state.bankaccount.bankaccountListUser.data[i].id == id)
            {
                ret = this.$store.state.bankaccount.bankaccountListUser.data[i][prop];
              break;
            }
        }
      }
      return ret;
    },  
    UpdateAccountType(){
        var data = {"value": this.OwnerType, "id":this.selectBankAccount};
        this.$store.dispatch('bankaccount/updateBankAccountListAccountType', data);
    },
    SelectPrev(){
      this.editBankAccount = false;
      this.selectBankAccount = this.selectBankAccountOld;
    },
    StartEditMode(){
      this.editBankAccount = true;   
    },
    Save: function (){
      if(this.$store.state.bankaccount.bankaccountListUser.data != null)
      {
        for (let i = 0; i < this.$store.state.bankaccount.bankaccountListUser.data.length; i++)
        {
            if(this.$store.state.bankaccount.bankaccountListUser.data[i].id == this.selectBankAccount)
            {
              var name = this.$store.state.bankaccount.bankaccountListUser.data[i].name;
              this.$store.state.bankaccount.bankaccountListUser.data[i].accountType = this.OwnerType;
              this.$store.state.bankaccount.bankaccountListUser.data[i].id_owner = this.OwnerId;
              this.$store.dispatch('bankaccount/saveBankAccount', this.$store.state.bankaccount.bankaccountListUser.data[i])
                .then(resp => {
                  if(resp.success == true)
                  {
                    this.$store.dispatch('bankaccount/getBankAccountListOfGuid', {id:this.OwnerId,ownertype:this.OwnerType,forTable:false});
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.BANKACCOUNT.SUCCESS.SAVE').replace("*1*",name)});
                    this.editBankAccount = false;
                    this.selectBankAccount = resp.id;
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.BANKACCOUNT.ERROR.SAVE').replace("*1*",name)});
                  }
                }, 
                error =>{
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.BANKACCOUNT.ERROR.SAVE').replace("*1*",name)+' '+error});
                });
                
              break;
            }
        }
      }
    }
  }
};
</script>
<style lang="css">
.selectBankAccount {
  margin:0px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%
}
.required{
  border:2px solid red;
}
.fullsize{
  width:100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%
  }
}
</style>
<template>
    <div >
        <div class="d-flex align-items-center btn-alignment">
            <vs-select v-if="!this.isEditMode"  :disabled="editAddress"
            class="selectAddr"
            v-bind:class="{ required: IsRequired }"
            :label="$t('USERADMIN.ADDRESS.USE_THIS')"
            v-model="selectAddr">
            <template v-for="(item,index) in addrBez">
              <vs-select-item v-if="item.value != -1" :key="index" :value="item.value" :text="item.text"  />
            </template>
            <vs-button class="mt-2 fullsize" size="small" color="primary" type="border" icon="add" @click="AddNewAddress()">{{$t('USERADMIN.ADDRESS.NEW_ADDRESS')}}</vs-button>
          </vs-select>
          <vs-input v-if="isEditMode" :label="$t('USERADMIN.ADDRESS.BEZEICHNUNG')" class="inputx" v-bind:class="{ required: IsRequired }" :placeholder="$t('USERADMIN.ADDRESS.BEZEICHNUNG')"  v-model="bez" />
        </div>
        <vs-divider position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.ADDRESS.STRASSE')"  class="inputx" :placeholder="$t('USERADMIN.ADDRESS.STRASSE')"  v-model="street1" />
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.ADDRESS.STRASSE2')"  class="ml-3 inputx" :placeholder="$t('USERADMIN.ADDRESS.STRASSE2')"  v-model="street2" />
        </div> 
        <div class="mt-2 default-input d-flex align-items-center">
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.ADDRESS.PLZ')"  class="inputx" :placeholder="$t('USERADMIN.ADDRESS.PLZ')"  v-model="plz" />
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.ADDRESS.ORT')"  class="ml-3 inputx" :placeholder="$t('USERADMIN.ADDRESS.ORT')"  v-model="city" />
        </div> 
        <div class="mt-2 default-input d-flex align-items-center">
            <vs-select :disabled="!isEditMode"
            :label="$t('USERADMIN.ADDRESS.LAND')"
            v-model="country_fk">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
          </vs-select>
            <vs-input :disabled="!isEditMode" :label="$t('USERADMIN.ADDRESS.ANMERKUNG')"  class="ml-3 inputx" :placeholder="$t('USERADMIN.ADDRESS.ANMERKUNG')"  v-model="notes" />
        </div>          
        <vs-divider v-if="!NewUser && editAddress" position="center" ></vs-divider>
        <div class="mt-2 default-input d-flex align-items-center">
          <vs-button v-if="!NewUser && editAddress"  type="border" icon="done" @click="Save" >{{$t('COM.SAVE')}}</vs-button>
          <vs-button class="ml-2" v-if="!NewUser && editAddress && selectAddr <= 0"  type="border" icon="clear" @click="SelectPrev" >{{$t('COM.CANCEL')}}</vs-button>
        </div>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';

export default {
  name: "Address",
  components:{
      
  },
  props:[
    'AddrId',
    'OwnerId',
    'EditMode',
    'IsRequired',
    'NewUser',
    'OwnerType'
  ],
   data: function (){
     return {
    selectAddr: this.AddrId,
    editAddress: this.EditMode,
    selectAddrOld: this.AddrId
     }
  },
  mounted () {
      if(this.$store.state.address.addressListUser.data == null)
        this.$store.dispatch('address/getAddrListOfUser', {id:this.OwnerId,ownertype:this.OwnerType,forTable:false});   
  },
  watch:{
    selectAddr: function (value,oldValue){
        this.$emit('SelectedAddressChanged',value);

        if(value <= 0)
        {
         this.StartEditMode();
        }

        this.selectAddrOld = oldValue;
    },
    EditMode: function (value)
    {
      this.editAddress = value;
    }
  },
  computed:{
    addrBez: function (){
      var ret = [];
      if(this.$store.state.address.addressListUser.data != null)
      {
        for (let i = 0; i < this.$store.state.address.addressListUser.data.length; i++)
        {
            var val = {"text": this.$store.state.address.addressListUser.data[i].bez, "value":this.$store.state.address.addressListUser.data[i].id};
            ret.push(val);
        }

        if(this.$store.state.address.addressListUser.data.length == 1)
        {
          this.StartEditMode();
        }
      }

      return ret;
    },
    countries: function(){
      return this.GetListOfCountriesForSelect();
    },
    isEditMode(){
        return this.editAddress || this.NewUser;
    },
    bez: 
    {
      get () {
        return this.GetAddrVal(this.selectAddr,'bez');
      },
      set (value) {
        var data = {"value": value, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListBez', data);
        this.UpdateAddressType();
      }
    },
    street1: 
    {
      get () {
        return this.GetAddrVal(this.selectAddr,'street1');
      },
      set (value) {
        var data = {"value": value, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListStreet1', data);
        this.UpdateAddressType();
      }
    },
    street2: 
    {
      get () {
        return this.GetAddrVal(this.selectAddr,'street2');
      },
      set (value) {
        var data = {"value": value, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListStreet2', data);
        this.UpdateAddressType();
      }
    },
    plz: 
    {
      get () {
        return this.GetAddrVal(this.selectAddr,'plz');
      },
      set (value) {
        var data = {"value": value, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListPlz', data);
        this.UpdateAddressType();
      }
    },
    city: 
    {
      get () {
        return this.GetAddrVal(this.selectAddr,'city');
      },
      set (value) {
        var data = {"value": value, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListCity', data);
        this.UpdateAddressType();
      }
    },
    notes: 
    {
      get () {
        return this.GetAddrVal(this.selectAddr,'notes');
      },
      set (value) {
        var data = {"value": value, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListNotes', data);
        this.UpdateAddressType();
      }
    },
    country_fk:
    {
      get () {
        return this.GetAddrVal(this.selectAddr,'country_fk');
      },
      set (value) {
        var data = {"value": value, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListCountry_fk', data);
        this.UpdateAddressType();
      }      
    }
  },
  methods:{
    ...helperMethods,
    AddNewAddress(){
      this.selectAddr = -1;
    },
    GetAddrVal: function (id,prop){
      var ret = "";
      if(this.$store.state.address.addressListUser.data != null)
      {
        for (let i = 0; i < this.$store.state.address.addressListUser.data.length; i++)
        {
            if(this.$store.state.address.addressListUser.data[i].id == id)
            {
                ret = this.$store.state.address.addressListUser.data[i][prop];
              break;
            }
        }
      }
      return ret;
    },
    SelectPrev(){
      this.editAddress = false;
      this.selectAddr = this.selectAddrOld;
    },
    StartEditMode(){
      this.editAddress = true;   
    },
    UpdateAddressType(){
        var data = {"value": this.OwnerType, "id":this.selectAddr};
        this.$store.dispatch('address/updateAddrListAddressType', data);
    },
    Save: function (){
      if(this.$store.state.address.addressListUser.data != null)
      {
        for (let i = 0; i < this.$store.state.address.addressListUser.data.length; i++)
        {
            if(this.$store.state.address.addressListUser.data[i].id == this.selectAddr)
            {
              var name = this.$store.state.address.addressListUser.data[i].bez;
              this.$store.state.address.addressListUser.data[i].addressType = this.OwnerType;
              this.$store.state.address.addressListUser.data[i].id_owner = this.OwnerId;
              this.$store.dispatch('address/saveAddr', this.$store.state.address.addressListUser.data[i])
                .then(resp => {
                  if(resp.success == true)
                  {
                    this.$store.dispatch('address/getAddrListOfUser', {id:this.OwnerId,ownertype:this.OwnerType,forTable:false});
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.ADDRESS.SUCCESS.SAVE').replace("*1*",name)});
                    this.editAddress = false;
                    this.selectAddr = resp.id;
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.ADDRESS.ERROR.SAVE').replace("*1*",name)});
                  }
                }, 
                error =>{
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.ADDRESS.ERROR.SAVE').replace("*1*",name)+' '+error});
                });
                
              break;
            }
        }
      }
    }
  }
};
</script>
<style lang="css">
.selectAddr {
  margin:0px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%
}
.required{
  border:2px solid red;
}
.fullsize{
  width:100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%
  }
}
</style>